@import "../AppVars.scss";

.cubeImg {
    padding: .5em;
    float:right;
    @media (min-width: $mobileBreak) {
        float:left;
        // padding:.5em
    }
}

.noIndentOnDesktop {
    text-indent: 2em;
    @media (min-width: $mobileBreak) {
        text-indent: 0;
    }
}
