@import '../../AppVars.scss';

.fullServiceCard {
  font-size: 125%;
}

.sylviaAcceleratesCard {
  font-size: 100%;
  @media (min-width: #{$mobileBreak}) {
    font-size:125%;
  }
}

.aMaineCompanyCard {
  font-size: 130%;
  flex: 1;
}


.mainSequenceKaizenMarquee {
  margin: 1em 2em 2em;
  // box-shadow: 5px 5px rgba(.2,.2,.2,.2);
  width: 80%;
  a {
      width: 50%;
      img {
          width: 50%;
      }
  }
}
