/*

*/
@import './AppVars.scss';
@import './partialCss/navigator.scss';

#root {
    min-width: 100vw;
}

.novemsite {
  text-align: center;
}

.novemsite {
    width: 100%;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: scroll;
}

.currentRouteContainer {
    position: relative;
    width: calc(100% - 100px);
    left:100px;
    @media #{$isBigger} {
        width: calc(100% - 200px);
        left:200px;
    }
    // right:0px;
}
