.contactButton {
    background-color: #30a040;
    color: white;
    border:none;
    border-radius: 15px;
    padding:5px 10px;
    margin: 5px 0px;

    transition: margin .25s;

    &:hover {
        color: black;
        margin-left:   0px;
        margin-right: 10px;
    }
    &:active {
        margin-left:  10px;
        margin-right: 0px;
        transition: margin .15s;
    }
    .contactButtonText {
        display: block;
    }
}
