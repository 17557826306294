@import url('https://fonts.googleapis.com/css?family=Roboto');
@import './AppVars.scss';
@import './partialCss/containerStructure.scss';
@import './partialCss/sharedBodyContent.scss';
@import './partialCss/bodyTransitions.scss';

// REMEMBER: Mobile first!

.compact-title {
    padding: .2em 0 .2em;
    font-size: 2em;
}

body {
    font-family: "Roboto";
    p {
        margin: .5em 0;
    }
}

.mobileOnly-inline {
    display: inline;
    @media (min-width: $mobileBreak) {
        display: none;
    }
}

.notMobileOnly-inline {
    display: none;
    @media (min-width: $mobileBreak) {
        display: inline;
    }
}

.columns {
    /* It's called columns because
    that's what's INSIDE IT. Therefore
    it, itself is a row. */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}


.columnsVert {
    /* It's called columns because
    that's what's INSIDE IT. Therefore
    it, itself is a row. */
    // margin: 0 auto;
    width: 100%;
    display: flex;
    flex-direction: column;

    @media(min-width: $largeBreak) {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
}

.flexColumn {
    flex:1;
}


.App-link {
  color: #61dafb;
}

// these are primereact classNames

body .novemsite .p-selectbutton {
    display: flex;
    justify-content: center;
    .p-button {
        background-color: #aac7de;
        color: #000;
        &:hover {
            background-color: #007ad9;
            color: #36ad36;
        }
        &.p-highlight {
            background-color:#36ad36;
            ::after {
                content: " \2714"
            }
        }
        &.p-highlight:hover {
            background-color: #36ad36;
            color: #000;
        }
        &:not(.p-disabled):not(.p-highlight):hover {
            background-color: #69ade2;
            border-color: #c8c8c8;
            color: #151;
        }
    }
}


.novemsite.novem-technology {
    background-color: #404040;
    color: white;
}
.novemsite.the-novem-cloud {
    background-color: #eaf1ea;
}

