$mobileBreak: 767px !default;
$largeBreak: 1000px !default;
$hugeBreak: 1300px !default;
$navButtonColor: #e0e0ff !default;
$bodyTransit: 500ms !default;

$controlSide: 100px;
$controlSideBig: 200px;

// mobile first
$isBigger: "(min-width:#{$mobileBreak})";

@mixin isDesktop {
    @media (min-width: #{$mobileBreak}) {
        @content;
    }
}
