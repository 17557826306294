@import '../AppVars.scss';

.contactForm.error {
    .topMessage {
        color: #a8554c;
    }
}
.contactForm.complete {
    .topMessage {
        color: #4ca855;
        .details {
            padding-top: 1em;
            color: #2e6b34;
            font-size: 70%;
            div {
                margin: .5em;
            }
        }
    }
}


.linkCell {
    width: 100%;
    padding: 1em;
    .linkTitle {

    }
    .linkLink {
        padding: .1em;
        padding-left: 1em;
    }

}

.nyimpl {
    margin: .1em;
    padding: .2em;
    border: dashed black 1px;
}

.centerControl {
    margin: 0 auto;
}

.OLDcenteringContainer{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.centeringContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.righteningContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: right;
}

.valueContainer {
    margin: 20px auto;
    .inputStandard {
        //max-width: 500px;
        width: 100%;
    }
    .formBox {
        width: 100%;
    }
    .valueTitle {
        margin: .5em 0;
    }
}

.pageBody.contactPageBody {
    text-indent:0;
    margin: 1em;
    display:flex;
    flex-direction: column;
    align-items: center;

    .contactItems {
        margin-top: 20px;
        // margin-top: 50px;
    }
}

.container {
    margin: 0 auto;
}

.rubber {
    height: 50px;
}

.centerDiv {
    margin: 0 auto;
}

// form field notice

.field-notice {
    padding: 0 1em;

    &.notice-status-invalid {
        color: red;
    }
    &.notice-status-valid {
        color: green;
        display: none;
    }
}
