
.navigatorPhantom {
    width: 200px;
    min-width: 200px;
    max-width: 200px;
}


.logoCell {
    height:150px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    .novemlogo {
      animation: App-logo-spin 1 .5s linear;
      width: 75px;
      @media (min-width: $mobileBreak) {
          width: 100px;
      }
      pointer-events: none;
    }

    .thenovemcloudlogo {
        animation: App-logo-spin 1 .5s linear;
        height: 100px;
        width: 100px;
        
        pointer-events: none;

    }
}
.pageButtonsCell {
    flex-grow:2;
    width:100%;
    display:flex;
    flex-direction: column;
    padding-bottom: 25px;
    .pageButton {
        // these are the <Link>s
        color: $navButtonColor;
        text-decoration: none;
        :link, :visited, :hover, :active {
            color:inherit;
            text-decoration: none;
        }
        &:hover {
            color: #20f033;
        }
        &:active {
            margin-left:20px;
            margin-right: 10px;
        }
        &.active {
            background-color: #207020;
            &:active {
                margin: 10px 15px 10px 15px;
            }
            &:hover {
                color: $navButtonColor;
            }
        }
        display: flex;
        flex-direction: row;
        flex: 1;
        .buttonContent {
            min-height: 2em;
        }
        border-radius: 10px 20px 30px 40px;
        @media (min-width:$mobileBreak) {
            border-radius: 15px 60px 60px 15px;
            .buttonContent {
                min-height: 0;
            }
        }
        //padding: 10px;
        margin: 10px 15px 10px 15px;
        background-color: #234566;
        align-items: center;
        justify-content: flex-center;
        .buttonContent {
            flex:1;
            vertical-align: middle;
            text-align: center;
            padding: 0 .5em;
        }
    }

}

.novemsite.the-novem-cloud {
    
    .pageButtonsCell {
        .pageButton {
            max-height:70px;
            .buttonContent {
                font-size: 140%;
            } 
        }   
    }
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.novem-technology .copyrightCell {
    color: rgb(206, 203, 203);
}
.copyrightCell {
    color: rgb(7, 24, 61);
    align-self: flex-end;
    font-size: .7rem;
    padding: 1rem;
    @media (max-height:$mobileBreak) {
        padding: .2rem;
    }
    width:100%;
    vertical-align: top ;
    line-height:1rem;
    margin-bottom: 20px;
    margin-top: 20px;
    span {
        line-height: 1rem;
        vertical-align: middle;
    }
}

.copysym {
    display: inline;
    font-size: .8rem;
}

.navigator {
  // background-color: #282c34;
  // background-color: white;
  height: 100vh;
  display: flex;
  position: fixed;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: white;
  min-height: 100vh;
  width:100px;
  min-width:100px;
  max-width:100px;
  z-index: 1000;
  font-size: 11px;

  @media (min-width:$mobileBreak) {
      width:200px;
      min-width:200px;
      max-width:200px;
      font-size: calc(10px + 1vmin);
  }
}
