@import '../AppVars.scss';

.highlightCardContainer {
  padding: 2.5px 5px;
  text-align: left;  
}

.desktopInline {
  @media (min-width: $mobileBreak) {
    display: inline;
  }
}

.highlightCard {
  p {
    text-indent: 0;
  }
  position: relative;
  background-color: #603b3b;
  color: "#fee";
  border-radius: 8px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 0 6px 10px;

  
  background-image: radial-gradient( 
    circle farthest-corner at 72.4% 21.7%,
    #94b6f1 0%,
    #608dd4 90.2% );
  //// mode prop
  //
  &.sylvia {
    background-image: radial-gradient( 
      circle farthest-corner at 25.4% 31.7%,
      #94b6f1 0%,
      #608dd4 110% );
  }
  &.lighter {
    background-image: radial-gradient( 
      circle farthest-corner at 22.4% 21.7%,
      #94b6f1 0%,
      #abbede 100.2% );
  }

  /// green
  &.techStack {
    background-image: radial-gradient( 
      farthest-corner at 20.4% 31.7%,
      #9ccf9b 0%,
      #34772f 150.2% );
  }
  &.techStackZod {
    background-image: radial-gradient( 
      farthest-corner at 50% 100%,
      #9ccf9b 0%,
      #34772f 150.2% );
  }

  &.techIsh {
    background-image: radial-gradient( 
      farthest-corner at 50% 0,
      #9ccf9b 0%,
      #34772f 150.2% );
  }

  //
  //
  ////

  .highlightCardTitle {
    background-color: rgba(1, 1, 1, 0.1);
    // text-align: center;
    color: rgb(222, 219, 239);
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
    border-radius: 5px;
    .titleContent {
      text-align: center;
      padding: 5px 10px;
    }
  }
}