.pageTransition-enter {
    opacity: 0;
    transform: translateX(-100%);
}

.pageTransition-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity $bodyTransit, transform $bodyTransit;
}


.pageTransition-exit {
    opacity: 1;
    position: absolute;
    // padding-left: 200px;
    transform: translateX(0);
}

.pageTransition-exit-active {
    opacity: 0;
    transform: translateX(100%);
    transition: opacity $bodyTransit, transform $bodyTransit;
}
