.novemInc {
    font-weight: 700;
}

.bodyTitle {
    font-family: 'Roboto';
    margin: 0;
    padding: .4em;
    border-radius: 10px;
    margin: .2em;
    //background-color: #bbbecc;
    // background-color: #5878ad;
    // background-color: #6a82ab;
    // background-color: #7d8fad;
    background-color: #95a8c7;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: .5em;
    @include isDesktop {
        padding: .2em .7em;
        font-size: 35px;
        margin-bottom: 1em;
    }
}

.pageBody, .bodyRightJustify {
    text-align: left;
    margin: .2em;
    text-indent: 1em;

    @include isDesktop {
        margin: 2em;
        text-indent: 2em;
    }
    // @media (min-width: $mobileBreak) {
    //     margin: 2em;
    //     text-indent: 2em;
    // }

    &.noMargin {
        margin: 2em 0;
    }
    ul {
        padding: 1em 0;
        // background-image: linear-gradient(to right, #a0ffa0, white);
    }
    ul li {
        // background-color: #f0fff0;\
        display: block;
        // border: solid #a0ffa0 1px;
        text-indent: 0;
        padding-left: 1em;
        // padding-top: 5px;
        // padding-bottom: 5px;
        
        div {
            &.nt-stack-item {
                padding-left: 10px;
                padding-right: 10px;   
                padding-top: 3px;
                padding-bottom: 3px;
            }
            background-image: linear-gradient(to right,rgba(66, 152, 66, .3), rgba(255,255,255,.1));
            //padding-right: 5em;
            a {
                padding-right: 3em;
                &:link {
                    color: black;
                }
                &:active {
                    color: black;
                }
                &:visited {
                    color: #304030;
                }
                &:hover {
                    color: #a0ffa0;
                }
                &:hover {
                    background-image: linear-gradient(to right, #304030, rgba(255,255,255,.1)); 
                }
                width: 100%;
                
            }
        }

    }
}


.dontIndentFirstLine {
    text-indent: 0;
}


.pageImage {
    display: none; // mobile first
    @media (min-width:$mobileBreak) {
        display: inline;
        margin: 1em 2em 2em;
        float: right;
        width: 25%;
        box-shadow: 5px 5px rgba(.2,.2,.2,.2);
    }

}

.verticalCenterSelf {
    align-self: center;
}

.mainSequenceKaizenHuge {
    display: none;
    @media (min-width: $hugeBreak) {
        display: inline;
        margin: 1em 2em 2em;
        box-shadow: 5px 5px rgba(.2,.2,.2,.2);
        float: right;
        width: 400px;
    }
    @media (max-width: $mobileBreak) {
        display:none
    }

}

.mainSequenceKaizenNormal {
    display: inline;
    margin: 1em 2em 2em;
    box-shadow: 5px 5px rgba(.2,.2,.2,.2);
    float: right;
    width: 45%;
    @media (max-width: $mobileBreak) {
        display:none
    }
    @media (min-width: $hugeBreak) {
        display: none;
    }

}

.homeDiagRight {
    display: inline;
    margin: 1em 2em 2em;
    box-shadow: 5px 5px rgba(.2,.2,.2,.2);
    float: left;
    width: 25%;
}

.homeDiagLeft {
    display: inline;
    margin: 1em 2em 2em;
    box-shadow: 5px 5px rgba(.2,.2,.2,.2);
    float: right;
    width: 35%;
}

.pageImageLeft {
    display: inline;
    margin: 1em 2em 2em;
    box-shadow: 5px 5px rgba(.2,.2,.2,.2);
    float: left;
    width: 25%;
}

.pageImageMobileContainer {
    width:100%;
}

.pageImageMobile {
    width: 75%;
    display: block;
    margin: 3em auto;
    @media (min-width:$mobileBreak) {
        display: none;
    }
}

.filldiv {
    position:absolute;
    width: 100%; //calc(100% - 200px);
    // padding-left: 200px;
    @media (max-width:$mobileBreak) {
        width: 100%; // calc(100% - 100px);
        // padding-left: 100px;
    }
}
